import styled from 'styled-components';
import { Accordion, Container } from 'react-bootstrap';
import iconExpand from '../../../images/icon-expand.png';
import iconPdf from '../../../images/icon-pdf-doc.png';
import AppColors from '../../../styles/colors';

export const MainContainer = styled(Container)`
  margin-top: -1px;
  background: #fff;
  max-width: 100% !important;
`;

export const InnerContainer = styled.div`
  width: 1700px;
  margin: 0 auto;
  padding: 70px 0 150px 0;
  font-family: 'Gotham-Regular', arial, sans-serif;
  font-size: 17px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.8);
  @media (max-width: 1799px) {
    width: 1300px;
  }
  @media (max-width: 1399px) {
    width: 1200px;
  }
  @media (max-width: 1199px) {
    width: 940px;
    padding-bottom: 100px;
  }
  @media (max-width: 991px) {
    width: 700px;
  }
  @media (max-width: 767px) {
    width: 100%;
    padding: 50px 30px;
    font-size: 15px;
    line-height: 23px;
  }
`;

export const Header = styled.h3`
  position: relative;
  margin: 0 0 40px 0;
  font-family: interstate, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 35px;
  line-height: 40px;
  color: ${AppColors.Blue};
  @media (max-width: 767px) {
    margin: 0 0 30px 0;
    font-size: 20px;
    line-height: 22px;
  }
`;

export const SubText = styled.div`
  margin: 0 0 40px 0;
  padding: 0;
  @media (max-width: 767px) {
    margin: 0 0 30px 0;
  }
`;

export const AccordionContainer = styled(Accordion)``;

export const AccordionItem = styled(Accordion.Item)`
  position: relative;
  padding: 0;
  margin: 0 0 20px 0;
  font-family: 'Gotham-Regular', arial, sans-serif;
  font-size: 17px;
  line-height: 32px;
  color: ${AppColors.Black};
`;

export const AccordionHeader = styled(Accordion.Header)`
  button {
    display: block;
    padding: 25px 85px 25px 25px;
    font-family: interstate, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 24px;
    line-height: 30px;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.15);
    color: ${AppColors.LightGreen};
    :hover {
      color: ${AppColors.HoverDarkGreen};
    }
    &::after {
      display: block;
      position: absolute;
      top: 20px;
      right: 20px;
      width: 40px;
      height: 40px;
      background: ${AppColors.White} url(${iconExpand}) no-repeat center right;
      background-size: 40px auto;
    }
  }
  .accordion-button: not(.collapsed) {
    background-color: ${AppColors.White};
    color: ${AppColors.LightGreen};
    box-shadow: none !important;
    :hover {
      color: ${AppColors.HoverDarkGreen};
    }
    ::after {
      background: ${AppColors.White} url(${iconExpand}) no-repeat center right;
      background-size: 40px auto;
      transform: rotate(45deg);
    }
  }
  .accordion-button:focus {
    border: 1px solid rgba(0, 0, 0, 0.15) !important;
    box-shadow: none;
  }
`;

export const AccordionBody = styled(Accordion.Body)`
  display: block;
  position: relative;
  margin: 0;
  padding: 25px 25px 15px 25px !important;
`;

export const ParaText = styled.div`
  margin: 0 0 40px 0;
  padding: 0;
  @media (max-width: 767px) {
    margin: 0 0 30px 0;
  }
  a {
    text-decoration: none !important;
    color: ${AppColors.LightGreen} !important;
  }
`;

export const ListContainer = styled.ul`
  margin: 0 !important;
  padding: 0 !important;
`;

export const ListItem = styled.li`
  margin: 0;
  list-style: none;
  background: url(${iconPdf}) no-repeat 0 15px;
  background-size: 27px auto;
  padding: 20px 0 20px 48px;
  ${ParaText} {
    margin: 0 !important;
  }
`;

export const Header3 = styled.h3`
  position: relative;
  margin: 0 0 40px 0;
  font-family: interstate, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 35px;
  line-height: 40px;
  color: ${AppColors.Blue};
  @media (max-width: 767px) {
    margin: 0 0 30px 0;
    font-size: 20px;
    line-height: 22px;
  }
`;

export const HorizontalBreak = styled.hr`
  margin: 0;
  clear: none;
  opacity: 1;
`;

export const LinkStyle = styled.a`
  text-decoration: none;
  color: ${AppColors.LightGreen};
  font-family: 'Gotham-Regular', arial, sans-serif;
  :hover {
    color: ${AppColors.LightBlue} !important;
  }
  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 23px;
  }
`;
